.divider {
  height: 1px;
  background: var(--secondary-color);
  border: 0px;
}

.divider-vertical {
  box-sizing: border-box;
  border-left: 1px solid var(--secondary-color);
  margin: 0px 20px;
}

/* PADDING */
.p-0 {
  padding: 0px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.pt-0 {
  padding-top: 0px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-0 {
  padding-left: 0px;
}

.pl-5 {
  padding-left: 5px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-0 {
  padding-right: 0px;
}

.pr-5 {
  padding-right: 5px;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}

/* MARGIN */
.m-0 {
  margin: 0px;
}

.m-5 {
  margin: 5px;
}

.m-10 {
  margin: 10px;
}

.m-20 {
  margin: 20px;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mx-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-0 {
  margin-left: 0px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-0 {
  margin-right: 0px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

/* CENTERING */
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-row {
  display: flex;
  justify-content: center;
}

.center-col {
  display: flex;
  align-items: center;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

/* WIDTH */
.w-250 {
  width: 250px !important;
}

.w-120 {
  width: 120px;
}

.w-100 {
  width: 100px;
}

.w-53 {
  width: 53px;
}

.w-50 {
  width: 50px;
}
