.card {
  position: relative;
  background: #ffffff;
  border: 2px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 5px #adb5bd;
  padding: 20px;
  margin: 10px;
  width: 100%;
}

.card-sm {
  max-width: 250px;
}

.card-lg {
  box-sizing: border-box;
  max-width: 580px;
}

.image {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.slick-track {
  display: flex;
}

.slick-slide div {
  height: 100%;
}
.slick-slide div div div {
  height: auto;
}

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100% !important;
}

.card-info-lg {
  position: absolute;
  top: 75px;
  bottom: 20px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.ant-carousel {
  overflow: hidden;
}

.card-icon {
  font-size: 25px;
  margin-right: 10px;
}
