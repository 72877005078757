.ant-input,
.ant-input-number-wrapper,
.ant-input-number-input {
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  padding: 5px 10px;
  margin-bottom: 5px;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  font-family: var(--font-family);
}

.ant-input-number {
  width: 100%;
}

input:disabled {
  background-color: #e9ecef;
}

input:focus-visible,
textarea:focus-visible,
.ant-input-number-input:focus-visible,
.ant-select-focused .ant-select-selector {
  outline: auto 1px;
  outline-color: var(--primary-color);
  outline-style: auto;
  outline-width: 1px;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  display: inline-block;
  margin-right: 4px;
  color: red;
  font-size: 16px;
  font-family: var(--font-family);
  line-height: 1;
  content: "*";
}

.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border-color: red;
}

.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  color: #00000073;
  font-size: 14px;
}

.ant-form-item-explain-error {
  margin-top: -5px;
  color: red;
}

.ant-checkbox {
  display: flex;
  align-items: center;
}

.ant-checkbox-input {
  height: 16px;
  width: 16px;
}

.ant-checkbox::before {
  display: inline-block;
  margin-right: 4px;
  color: red;
  font-size: 16px;
  font-family: var(--font-family);
  line-height: 1;
  content: "*";
}

.ant-input-suffix {
  margin-left: -25px;
}

input[type="time" i] {
  border: none;
  width: 42px;
  padding: 0px;
  margin: 0px;
  overflow: visible;
  background-color: white;
}

input[type="time" i]:focus-visible {
  outline: 0px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  margin-left: -50px;
  width: 40px;
}

.time-input-wrapper {
  box-sizing: border-box;
  height: 32px;
  font-size: 16px;
  padding: 5px 10px;
  margin-bottom: 5px;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
}

.ant-input-number-wrapper {
  padding: 0px;
}

.ant-input-number-wrapper input {
  padding: 5px;
  margin: 0px;
  border: none;
  font-size: 16px;
  font-family: var(--font-family);
  line-height: 1;
}

.addon-before input {
  border-radius: 0px 5px 5px 0px;
}

.addon-after input {
  border-radius: 5px 0px 0px 5px;
}

.ant-input-number-wrapper input:focus-visible {
  outline: 0px;
}

.ant-input-number-wrapper:focus-within {
  outline: auto 1px;
  outline-color: var(--primary-color);
  outline-style: auto;
  outline-width: 1px;
}

.ant-input-number-group {
  position: relative;
  display: flex;
  align-items: center;
}

.ant-input-number-group-addon {
  position: relative;
  padding: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #e9ecef;
}

.addon-before .ant-input-number-group-addon {
  padding-right: 6px;
  margin-left: 0.5px;
  border-radius: 5px 0px 0px 5px;
  border-right: 1px solid var(--secondary-color);
}

.addon-after .ant-input-number-group-addon {
  padding-left: 6px;
  margin-right: 0.5px;
  border-radius: 0px 5px 5px 0px;
  border-left: 1px solid var(--secondary-color);
}

.ant-select {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;
  margin-bottom: 5px;
}

.ant-select-selector {
  padding: 0 11px;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  height: 32px;
}

.ant-select-selection-search {
  position: absolute;
  inset: 0 11px;
}

.ant-select-selection-item,
.ant-select-selection-placeholder {
  padding-right: 18px;
  line-height: 30px;
}

.ant-select-arrow {
  position: absolute;
  top: 50%;
  right: 11px;
  margin-top: -6px;
  color: var(--secondary-color);
  font-size: 12px;
  pointer-events: none;
}

.ant-select-dropdown,
.ant-menu {
  position: absolute;
  width: auto !important;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  padding: 4px 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
}

.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft,
.ant-menu.ant-slide-up-leave.ant-slide-up-leave-active.ant-menu-placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
  display: none;
}

.ant-select-item,
.ant-menu-item {
  padding: 5px 10px;
  cursor: pointer;
  pointer-events: all;
}

.ant-select-no-content {
  padding: 5px 10px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled),
.ant-menu-item-active:not(.ant-menu-item-disabled) {
  background-color: #f5f5f5;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.ant-menu-item-selected:not(.ant-menu-item-disabled) {
  color: rgba(0, 0, 0, 0.85);
  background-color: var(--background-color);
}

.ant-select-item-option-disabled,
.ant-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.ant-select-item-option-disabled.ant-select-item-option-selected,
.ant-menu-item-disabled.ant-menu-item-selected {
  background-color: #f5f5f5;
}

.ant-select-selection-search-input {
  padding-top: 5px;
  padding-bottom: 3px;
  background: 0 0;
  border: none;
  outline: none;
  appearance: none;
  height: 30px;
  width: 100%;
  font-size: 16px;
  font-family: var(--font-family);
}

.ant-select-selection-search-input:focus-visible {
  outline: none;
}

.ant-switch {
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: #00000040;
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-switch-checked {
  background-color: var(--primary-color);
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}
.ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}
.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: "";
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}
.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked
  .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-loading-icon.anticon {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: top;
}
.ant-switch-checked .ant-switch-loading-icon {
  color: #1890ff;
}
