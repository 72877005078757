.alert-wrapper {
  position: absolute;
  top: 42.8px;
  left: 0px;
  right: 0px;
  margin: 20px;
  z-index: 1100;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ant-alert {
  transform-origin: top;
  font-size: 18px;
  font-weight: bold;
  word-wrap: break-word;
  border-radius: 5px;
  max-width: 800px;
  min-width: 200px;
}

.ant-alert-content {
  padding: 10px 20px;
}

.ant-alert-error {
  color: #842029;
  background-color: #ff4d4f;
  border: 1px solid #d9363e;
}

.ant-alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border: 1px solid #ffecb5;
}

.ant-alert-info {
  color: #055160;
  background-color: #cfe2ff;
  border: 1px solid #b6d4fe;
}

.ant-alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border: 1px solid #badbcc;
}

.alert-show {
  animation: alertDown 500ms ease-in-out;
}

.alert-hide {
  animation: alertUp 500ms ease-in-out;
}

@keyframes alertDown {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes alertUp {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
