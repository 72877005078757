.fc-button-primary {
  color: white;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.fc-button-primary:hover {
  background-color: #0a58ca !important;
  border-color: #0a53be !important;
}

.fc-button-active {
  color: #fff;
  background-color: #0a58ca !important;
  border-color: #0a53be !important;
}

.fc-button:focus {
  box-shadow: 0 0 0 0 !important;
}

.fc-toolbar-title {
  text-align: center;
}

.fc-toolbar-chunk {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.fc-toolbar-chunk:first-child {
  justify-content: start;
}

.fc-toolbar-chunk:last-child {
  justify-content: end;
}

.fc-event {
  cursor: pointer;
}

.fc-daygrid-event-dot {
  border-color: var(--primary-color) !important;
}

.fc-daygrid .fc-scroller {
  overflow: hidden !important;
}

.fc-toolbar-chunk {
  width: 33.3%;
}

@media screen and (max-width: 568px) {
  .fc-button-group,
  .fc-today-button,
  .fc-edit-button,
  .fc-toolbar-title {
    transform: scale(0.8) !important;
  }
  .fc-daygrid-more-link {
    top: -25px;
  }
  .fc-today-button {
    margin-left: 5px !important;
  }
  .fc-edit-button {
    margin-right: 5px !important;
  }
  .fc-toolbar-chunk:first-child {
    margin-left: -10px;
  }
  .fc-toolbar-chunk:last-child {
    margin-right: -10px;
  }
}
