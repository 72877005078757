.navbar {
  background: var(--primary-color);
  color: white;
  padding: 5px 20px;
  margin-bottom: 10px;
  overflow: hidden;
}

.menu-show-1 {
  animation: menuDown1 50ms ease-in-out;
}

.menu-hide-1 {
  animation: menuUp1 50ms ease-in-out;
}

@keyframes menuDown1 {
  0% {
    height: 0px;
  }
  100% {
    height: 20px;
  }
}

@keyframes menuUp1 {
  0% {
    height: 20px;
  }
  100% {
    height: 0px;
  }
}

.menu-show-2 {
  animation: menuDown2 100ms ease-in-out;
}

.menu-hide-2 {
  animation: menuUp2 100ms ease-in-out;
}

@keyframes menuDown2 {
  0% {
    height: 0px;
  }
  100% {
    height: 50px;
  }
}

@keyframes menuUp2 {
  0% {
    height: 50px;
  }
  100% {
    height: 0px;
  }
}

.menu-show-3 {
  animation: menuDown3 150ms ease-in-out;
}

.menu-hide-3 {
  animation: menuUp3 150ms ease-in-out;
}

@keyframes menuDown3 {
  0% {
    height: 0px;
  }
  100% {
    height: 80px;
  }
}

@keyframes menuUp3 {
  0% {
    height: 80px;
  }
  100% {
    height: 0px;
  }
}

.menu-show-4 {
  animation: menuDown4 200ms ease-in-out;
}

.menu-hide-4 {
  animation: menuUp4 200ms ease-in-out;
}

@keyframes menuDown4 {
  0% {
    height: 0px;
  }
  100% {
    height: 110px;
  }
}

@keyframes menuUp4 {
  0% {
    height: 110px;
  }
  100% {
    height: 0px;
  }
}

.menu-show-5 {
  animation: menuDown5 250ms ease-in-out;
}

.menu-hide-5 {
  animation: menuUp5 250ms ease-in-out;
}

@keyframes menuDown5 {
  0% {
    height: 0px;
  }
  100% {
    height: 140px;
  }
}

@keyframes menuUp5 {
  0% {
    height: 140px;
  }
  100% {
    height: 0px;
  }
}

.navbar-item {
  color: white;
  margin: 10px 5px;
  cursor: pointer;
}

.navbar-item-active {
  color: white;
  margin: 10px 5px;
  cursor: pointer;
  font-weight: bold;
}

.navbar-item:hover {
  font-weight: bold;
}

.navbar-icon {
  padding: 2px;
  font-size: 25px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
  border: 1px white;
  border-radius: 1px;
}

nav a {
  text-decoration: none;
}
