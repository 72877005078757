.arrow {
  appearance: none;
  user-select: none;
  outline: none !important;
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding: 0;
  border: none;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  transition: all 0.25s ease-out;
}

.arrow-left {
  border-right: 10px solid var(--secondary-color);
  left: 20px;
}

.arrow-left:hover {
  border-right-color: var(--primary-color);
}

.arrow-right {
  border-left: 10px solid var(--secondary-color);
  right: 20px;
}

.arrow-right:hover {
  border-left-color: var(--primary-color);
}

.calendar-container {
  font-size: 5px;
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  overflow: hidden;
  margin-left: 1px;
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--primary-color);
}

.calendar-grid {
  display: grid;
  grid-template: repeat(4, 25px) / repeat(7, calc(100% / 7));
}

.calendar-month {
  font-weight: 500;
  font-size: 20px;
  color: var(--primary-color);
  text-align: center;
  padding: 5px 5px;
  word-spacing: 5px;
  user-select: none;
}

.calendar-day {
  font-weight: 600;
  font-size: 12px;
  color: var(--primary-color);
  padding: 2px;
}

.calendar-date {
  font-size: 12px;
  cursor: pointer;
  color: var(--primary-color);
  padding: 2px;
  transition: all 0.4s ease-out;
  border-radius: 4px;
}

.calendar-date:hover {
  background: var(--background-color);
}

.calendar-date-unavailable {
  font-size: 12px;
  color: var(--secondary-color);
  padding: 2px;
  transition: all 0.4s ease-out;
  border-radius: 4px;
}

.highlighted-calendar-date {
  color: white !important;
  background: var(--primary-color) !important;
}

.today-calendar-date {
  color: white !important;
  background: var(--primary-color) !important;
}

.timepicker {
  margin-top: 5px;
  padding-left: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 85px;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
}
