@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");
:root {
  --primary-color: #0d6efd;
  --secondary-color: #6c757d;
  --danger-color: #dc3545;
  --background-color: #e6f0fe;
  --font-family: "Quicksand", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  background: var(--background-color);
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.container {
  padding: 10px 20px;
  display: flex;
  justify-content: center;
}

.content {
  position: fixed;
  top: 42px;
  bottom: 0px;
  z-index: -1;
  width: 100%;
  padding-top: 10px;
  overflow: auto;
}

.primary-button {
  border: 1px var(--primary-color);
  background: var(--primary-color);
  color: white;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin-right: 7px;
  min-width: 50px;
  word-wrap: normal;
}

.secondary-button {
  background: var(--secondary-color);
  border: 1px var(--secondary-color);
  color: white;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin-right: 7px;
  min-width: 50px;
  word-wrap: normal;
}

.danger-button {
  background: var(--danger-color);
  border: 1px var(--danger-color);
  color: white;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin-right: 7px;
  min-width: 50px;
  word-wrap: normal;
}

.doctor-button {
  background: var(--primary-color);
  border: 1px var(--primary-color);
  color: white;
  height: 50px;
  border-radius: 5px;
  padding: 4px;
  cursor: pointer;
  width: 24px;
  font-size: 16px;
  word-wrap: normal;
  margin-left: 10px;
}

@import "./alert.css";
@import "./calendar.css";
@import "./card.css";
@import "./datepicker.css";
@import "./form.css";
@import "./modal.css";
@import "./navbar.css";
@import "./result.css";
@import "./utils.css";
