.ant-modal-wrap {
  z-index: 1000;
  position: fixed;
  inset: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  display: flex;
  justify-content: center;
}

.ant-modal-mask {
  position: fixed;
  inset: 0;
  z-index: 1000;
  height: 100%;
  background-color: #00000053;
}

.ant-modal {
  box-sizing: border-box;
  padding: 20px;
  font-size: 20px;
  position: absolute;
  top: 80px;
  width: auto;
  max-width: calc(100vw - 40px);
  background-color: white;
  border-radius: 5px;
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  pointer-events: auto;
}

.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: #00000073;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: 0 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  transform: scale(1.5);
}

.ant-modal-header {
  padding-right: 10px;
  padding-bottom: 10px;
  background: white;
  border-bottom: 1px solid var(--secondary-color);
  border-radius: 2px 2px 0 0;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.ant-modal-body {
  padding: 10px 0px;
  font-size: 16px;
  word-wrap: break-word;
}

.ant-modal-footer {
  text-align: right;
  background: 0 0;
  border-radius: 0 0 2px 2px;
  display: flex;
  justify-content: center;
}

.fade-enter {
  opacity: 0;
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-leave {
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
  animation-play-state: paused;
}

.fade-enter.fade-enter-active {
  animation-name: fadeIn;
  animation-play-state: running;
}

.fade-leave.fade-leave-active {
  animation-name: fadeOut;
  animation-play-state: running;
}

.fade-enter .ant-modal {
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-play-state: paused;
}

.fade-enter-active .ant-modal {
  animation-name: SlideIn;
  animation-play-state: running;
}

.fade-leave .ant-modal {
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-play-state: paused;
}

.fade-leave-active .ant-modal {
  animation-name: SlideOut;
  animation-play-state: running;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes SlideIn {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes SlideOut {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-50px);
  }
}
