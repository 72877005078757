.ant-result-icon {
  display: flex;
  justify-content: center;
  font-size: 40px;
}

.ant-result-title {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.ant-result-subtitle {
  display: flex;
  justify-content: center;
  text-align: center;
}
